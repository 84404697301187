import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import Tip from './Tip';

const el = document.getElementById('vue_tip_balloon');
if (el) {
  const app = createApp(Tip);
  initSentry(app);
  window.vue_tip_balloon = app.mount('#vue_tip_balloon');
}
