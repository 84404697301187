<template>
<div v-show="visible" class="vue_tip_balloon__container">
  <img @click="closeTip" src="/dist/css/images/close.png" alt="Закрыть" class="vue_tip_balloon_close" />
  <div>
    <div class="vue_tip_balloon_wrapper">
      <b class="vue_tip_balloon_title">Вы искали</b>
    </div>

    <div class="vue_tip_balloon_wrapper">
      &#8220;<span class="vue_tip_balloon_search_text" v-html="highlightedText" :title="fullRawText"></span>&#8221;
    </div>

    <div class="vue_tip_balloon_wrapper">
      <p>Мы отметили эти типы компаний в конфигураторе!</p>
      <p>Вы можете оставить их или сбросить.</p>
    </div>
  </div>

  <div class="vue_tip_balloon_buttons">
    <div class="vue_tip_button_wrapper">
      <button @click="closeTip" class="vue_tip_balloon_button vue_tip_balloon_ok_button">
        Ок
      </button>
    </div>
    <button @click="closeTipWithUndo" class="vue_tip_balloon_button vue_tip_balloon_cancel_button">
      Сбросить
    </button>
  </div>
</div>
</template>

<script>
import { getTipData } from './api';

export default {
  name: 'Tip',
  data() {
    return {
      visible: false,
      highlightedText: '',
      fullRawText: '',
      companyTypes: []
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        document.addEventListener('click', this.outsideEventListener);
      } else {
        document.removeEventListener('click', this.outsideEventListener);
      }
    }
  },
  methods: {
    outsideEventListener(e) {
      if (window.jQuery(e.target).closest('#id_new_companies, #id_okved, #id_key_search').length > 0) {
        this.closeTip();
      }
    },
    async initTipData() {
      const data = await getTipData(location.href);
      if (data) {
        if (data['company_type'] && data['company_type'].length > 0) {
          this.setCompanyTypes(data['company_type']);
          this.setHighlightedText(data['highlighted_text']);
          this.setFullRawText(data['full_text']);
          this.openTip();
        }
      }
    },
    openTip() {
      if (this.companyTypes.length > 0) {
        this.visible = true;
        this.useTip();
      }
    },
    closeTip() {
      this.visible = false;
      this.closeCompanyTypeSelector();
    },
    closeTipWithUndo() {
      this.closeTip();
      this.undoTip();
    },
    setHighlightedText(value) {
      this.highlightedText = value;
    },
    setFullRawText(value) {
      this.fullRawText = value;
    },
    setCompanyTypes(value) {
      this.companyTypes = value;
    },
    useTip() {
      if (this.companyTypes.length > 0) {
        this.openCompanyTypeSelector();
        this.chooseCompanyTypes();
      }
    },
    undoTip() {
      if (this.companyTypes.length > 0) {
        this.resetCompanyTypes();
        this.closeCompanyTypeSelector();
      }
    },
    chooseCompanyTypes() {
      window.vue_main_base_configurator.$store.dispatch('multipleToggleIncludeType', {
        'selectorType' : 'companyType',
        'ids' : this.companyTypes
      });
    },
    resetCompanyTypes() {
      window.vue_main_base_configurator.$store.dispatch('resetMultipleToggleIncludeType', {
        'selectorType' : 'companyType',
        'ids' : this.companyTypes
      });
    },
    openCompanyTypeSelector() {
      window.vue_main_base_configurator.setShowSelector('companyType', true);
    },
    closeCompanyTypeSelector() {
      window.vue_main_base_configurator.setShowSelector('companyType', false);
    },
    initText() {
      const params = new URLSearchParams(location.hash.substring(1));
      this.text = params.get('key');
    },
    initCoordinates() {
      this.$el.style.top = '20px';
      this.$el.style.left = '20px';
    }
  },
  mounted() {
    this.initText();
    this.initCoordinates();
  },
};
</script>

<style scoped>
.vue_tip_balloon__container {
  width: 225px;
  height: 270px;
  z-index: 199;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 20px;
  background-color: var(--background-color-configurator);
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
}

.vue_tip_balloon_close {
  position: absolute;
  top: 0;
  right: 0;
  margin: -15px;
  cursor: pointer;
}

.vue_tip_balloon_wrapper {
  margin-bottom: 15px;
}

.vue_tip_button_wrapper {
  margin-right: 20px;
}

.vue_tip_balloon_title {
  font-size: 16px;
}

.vue_tip_balloon_buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.vue_tip_balloon_button {
  cursor: pointer;
  text-align: center;
}

.vue_tip_balloon_ok_button {
  background-color: #8B9C40;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  border: none;
  padding: 5px 30px;
}

.vue_tip_balloon_ok_button:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
}

.vue_tip_balloon_cancel_button {
  background: none;
  border: none;
}

.vue_tip_balloon_cancel_button:hover {
  text-decoration: underline;
}

.vue_tip_balloon_search_text {
  font-style: italic;
  font-size: 14px;
  max-width: 180px;
}
</style>
