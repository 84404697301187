export const getTipData = async (url) => {
  return fetch(
    `/api/parse_client_request`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({ url: url })
    }
  )
    .then(res => res.json())
    .catch(() => {
      return [];
    });
};
